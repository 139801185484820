<template>
  <v-app>
    <v-app-bar :color="env == 'DEV' ? 'red lighten-3' : '#ffffff'" app>
      <div class="d-flex align-center">
        <v-img
          @click="$router.replace({ path: '/' })"
          alt="AchleyVance Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/av_logo_0.png')"
          transition="scale-transition"
        />
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        {{ title }}
        {{ $route.meta.tab ? "/ " + $route.meta.tab : null }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-title>
        <b>{{ employee && employee.initials }}</b>
        <i class="mx-2">{{ employee && employee.title }}</i>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="!user" @click="signIn" color="primary">LOG IN</v-btn>
      <template v-else>
        <v-menu
          offset-y
          :close-on-content-click="false"
          v-model="showMenu"
          style="max-width: 300px"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-list-item style="flex: inherit" v-bind="attrs" v-on="on">
              <v-list-item-avatar>
                <v-img :alt="user.displayName" :src="user.photoURL"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="user.displayName"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="user.email"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list v-if="user">
            <v-list-item dense v-if="devArr.indexOf(user.email) > -1">
              <v-list-item-title>
                <v-row>
                  <v-col class="mt-3">
                    <v-input dense hide-details> Adjusted employee:</v-input>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :value="employee && employee.initials"
                      dense
                      hide-details
                      @change="(v) => adjustEmployee(v)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-account-convert</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item dense v-if="devArr.indexOf(user.email) > -1">
              <v-list-item-title>
                <v-row>
                  <v-col class="mt-3">
                    <v-input dense hide-details> Enviroment:</v-input>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="env"
                      :items="['DEV', 'PROD']"
                      dense
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-account-convert</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item dense @click="saveSettings">
              <v-list-item-title>Save settings</v-list-item-title>
              <v-list-item-icon
                ><v-icon>mdi-account-cog</v-icon></v-list-item-icon
              >
            </v-list-item>
            <v-list-item dense @click="refreshToken">
              <v-list-item-title>
                {{
                  userSettings && userSettings.token ? "Refresh " : "Provide "
                }}
                Access to Google Services
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-account-lock</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense @click="signOut">
              <v-list-item-title>Sign Out</v-list-item-title>
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <v-main class="text-center">
      <router-view
        v-if="employee"
        :employee="employee"
        :userSettings="userSettings"
        :key="env"
        ref="app"
      />
      <h3 v-else class="ma-6">Please, login to access the app. (v{{ ver }})</h3>
    </v-main>
    <v-snackbar v-model="snackbar" :color="snackbar_color" bottom>
      {{ snackbar_text }}
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
/* global google */
import Vue from "vue";
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
import { getDatabase, ref, onValue, set, off } from "firebase/database";

export default Vue.extend({
  name: "App",
  provide() {
    return {
      postData: this.postData,
      showMessage: this.showMessage,
      format: this.format,
    };
  },
  data: () => ({
    ver: 1.106,
    scopes: [
      "email",
      "profile",
      "https://www.googleapis.com/auth/drive",
      "https://www.googleapis.com/auth/documents",
      "https://www.googleapis.com/auth/calendar",
      "https://www.googleapis.com/auth/spreadsheets",
    ],
    env: "DEV",
    devArr: [
      "developer@ashleyvance.com",
      "truitt@ashleyvance.com",
      "tyler@ashleyvance.com",
    ],
    formatUSD: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }),
    formatUSD_: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    formatDEC: new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }),
    showMenu: false,
    title: null,
    user: null,
    employee: null,
    error: null,
    snackbar: false,
    snackbar_color: "primary",
    snackbar_text: null,
    auth: getAuth(),
    db: getDatabase(),
    userRef: null,
    userSettings: null,
    api_url: process.env.VUE_APP_API_URL || "http://localhost:8080",
  }),
  methods: {
    saveSettings() {
      let panes = this.$refs.app && this.$refs.app.panes;
      if (panes) {
        set(ref(this.db, "users/" + this.user.uid + "/panes"), panes).then(
          () => {
            this.showMessage("Settings were updated.", "success");
          }
        );
      }
    },
    signOut() {
      signOut(this.auth);
    },
    async signIn() {
      let provider = new GoogleAuthProvider();
      //this.scopes.forEach((scope) => provider.addScope(scope));
      signInWithPopup(this.auth, provider)
        .then((result) => {
          //  this.credential = GoogleAuthProvider.credentialFromResult(result);
          this.user = result.user;
        })
        .catch((error) => {
          this.showMessage(error.message, "error");
          this.user = null;
        });
    },
    adjustEmployee(initials = "") {
      this.postData("/users", { initials })
        .then((data) => {
          if (data && data[0]) {
            this.employee = data[0];
          } else {
            this.showMessage(
              "There is no '" + initials + "' user found",
              "error"
            );
          }
        })
        .catch((error) => {
          this.showMessage(error.message, "error");
        });
      this.showMenu = false;
    },
    showMessage(text = "", color = "info") {
      this.snackbar = true;
      this.snackbar_color = color;
      this.snackbar_text = text;
    },
    format(value, type) {
      value = isNaN(value) ? 0 : +value;
      if (type == "USD") return this.formatUSD.format(value);
      if (type == "USD_") return this.formatUSD_.format(value);
      if (type == "DEC") return this.formatDEC.format(value);
      return value;
    },
    async postData(url = "/", data = {}, toAddToken) {
      console.log("REQUEST", url, data);
      if (
        toAddToken &&
        (!this.user || !this.userSettings || !this.userSettings.token)
      ) {
        throw new Error("Provide App with access to Google services.");
      }
      const response = await fetch(this.api_url + url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: JSON.stringify(
          Object.assign(
            {
              env: this.env,
              token: toAddToken && this.user && this.userSettings.token,
            },
            data
          )
        ),
      });
      console.log(response);
      try {
        if (response.status == 200) {
          return await response.json();
        } else {
          let json = await response.json();
          throw new Error(json.message);
        }
      } catch (err) {
        throw new Error(response.statusText + ". " + err.message);
      }
    },
    refreshToken() {
      this.showMenu = false;
      let interval = setInterval(() => {
        if (google) {
          clearInterval(interval);
          google.accounts.oauth2
            .initCodeClient({
              client_id: process.env.VUE_APP_CLIENT_ID,
              callback: (credentials) => {
                this.postData("/users", { credentials })
                  .then((data) => {
                    if (data) {
                      set(
                        ref(this.db, "users/" + this.user.uid + "/token"),
                        data
                      ).then(() => {
                        this.showMessage("Access granted", "success");
                      });
                    }
                  })
                  .catch((error) => {
                    this.showMessage(error.message, "error");
                  });
              },
              scope: this.scopes.join(" "),
              ux_mode: "popup",
              select_account: true,
            })
            .requestCode();
        }
      }, 1000);
    },
  },

  mounted() {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.showMenu = false;
        this.user = user || null;
        this.userRef = ref(this.db, "users/" + user.uid);
        onValue(this.userRef, (snapshot) => {
          this.userSettings = snapshot.val();
        });
        this.postData("/users", { email: user.email })
          .then((data) => {
            if (data && data[0]) {
              this.employee = data[0];
            } else {
              this.employee = null;
              this.showMessage(
                data.message || "There is no such a user exist",
                "error"
              );
            }
          })
          .catch((error) => {
            this.showMessage(error.message, "error");
          });
      } else {
        this.user = null;
        this.employee = null;
        this.showMenu = false;
        if (this.userRef) off(this.userRef);
        this.userSettings = null;
        if (this.$router.currentRoute.path != "/")
          this.$router.replace({ path: "/" });
      }
    });
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.title = to.meta.title;
        document.title = to.meta.title || "Ashley & Vance App";
      },
    },
  },
});

// eslint-disable-next-line no-unused-vars
function login(user) {
  console.log(user);
}
</script>
<style>
/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.small-text {
  font-size: 10px;
}
.smallIconWrapper {
  width: 16px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.smallIcon {
  font-size: 10px !important;
  color: rgb(109, 133, 139);
}
.overview {
  font-size: 12px;
  color: #224c58;
  font-weight: bold;
  border-color: rgb(191, 191, 191);
  border-bottom-style: none;
  border-left-style: none;
}

.overview p {
  margin-bottom: 0;
  white-space: nowrap;
}

.overview-block {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.overview-text {
  color: #828282;
}

.overview-data {
  position: absolute;
  color: #6c6c6c;
  bottom: 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background: #ccc;
}

.active-tab {
  color: black !important;
  font-weight: bold;
}

.active-tab .overview-text {
  display: none;
}

.active-tab .overview-data {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  color: black;
  background: #f2f2f2;
  box-shadow: 2px -2px 5px 3px grey;
}

.invoiceStrikethrough {
  color: rgb(255, 0, 000) !important;
  text-decoration: line-through;
}
</style>
