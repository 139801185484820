import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { initializeApp } from "firebase/app";

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyA4V4vdUQL5pKui7ehl3Pvd5eppFnkfPTs",
  authDomain: "ancient-ship-114919.firebaseapp.com",
  projectId: "ancient-ship-114919",
  storageBucket: "ancient-ship-114919.appspot.com",
  messagingSenderId: "497161601265",
  appId: "1:497161601265:web:7c5b5109a4a71c5ca8a950",
};

initializeApp(firebaseConfig);

export const eventBus = new Vue();
export const store = Vue.observable({
  employee: null,
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
