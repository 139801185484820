<template>
  <v-toolbar>
    <v-btn
      @click="$router.replace({ path: '/InvoiceMachine/drafts' })"
      class="mx-2"
      color="primary"
      >Invoice Machine
    </v-btn>

    <v-btn
      @click="$router.replace({ path: '/Admin' })"
      class="mx-2"
      color="secondary"
      >Admin
    </v-btn>
  </v-toolbar>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "HomePage",

  data: () => ({}),
});
</script>
