import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: { title: null },
  },
  {
    path: "/Admin/",
    name: "Admin",
    props: true,
    component: () => import("../views/AdminView.vue"),
    meta: { title: "Admin" },
  },
  {
    path: "/InvoiceMachine/",
    name: "Invoice Machine",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/InvoiceMachineView.vue"),
    meta: { title: "Invoice Machine" },
    children: [
      {
        path: "wip",
        component: () => import("../components/ImWip.vue"),
        meta: { title: "Invoice Machine", tab: "Work In Progress" },
      },
      {
        path: "drafts",
        component: () => import("../components/ImDrafts.vue"),
        meta: { title: "Invoice Machine", tab: "Drafts" },
      },
      {
        path: "review",
        component: () => import("../components/ImDrafts.vue"),
        meta: { title: "Invoice Machine", tab: "In Review" },
      },
      {
        path: "finaled",
        component: () => import("../components/ImFinaled.vue"),
        meta: { title: "Invoice Machine", tab: "Finaled" },
      },
      {
        path: "sent",
        component: () => import("../components/ImSent.vue"),
        meta: { title: "Invoice Machine", tab: "Sent" },
      },
    ],
  },
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
